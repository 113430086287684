var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"back-to":_vm.backToPath,"mobile-ready":"","main-sidebar":false,"loading-title":"UC Competitions"},scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('div',{staticClass:"xl:max-w-screen-lg mx-auto px-2 md:px-5 flex flex-col gap-5"},[(!_vm.loading && _vm.tournament && _vm.league && _vm.edition && _vm.sport)?_c('BannerTournament',{attrs:{"edition":_vm.edition,"league":_vm.league,"sport":_vm.sport,"tournament":_vm.tournament,"num-teams":_vm.sport?.stats?.approved_teams ?? 0,"num-players":_vm.sport?.stats?.total_players ?? 0}}):_c('div',{staticClass:"h-80 rounded-3xl bg-gray-200 animate-pulse"}),(_vm.tournament && _vm.tournament.description[_vm.language])?_c('fw-panel',{attrs:{"title":"Sobre o torneio"}},[_c('div',{staticClass:"content text-sm",domProps:{"innerHTML":_vm._s(_vm.tournament.description[_vm.language])}})]):_vm._e(),(!_vm.team && _vm.tournament && _vm.canSignup)?_c('div',{staticClass:"bg-white rounded-xl pt-5 px-5 pb-10 text-primary text-center font-semibold flex flex-col items-center"},[_c('approvedTeamsIcon',{staticClass:"h-32 w-32"}),_c('div',{staticClass:"text-xl mb-4 -mt-3"},[_vm._v(" As inscrições estão abertas! "),_c('div',{staticClass:"text-base"},[_vm._v("Inscreve já a tua equipa.")])]),_c('fw-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.signup()}}},[_vm._v("Criar equipa")])],1):_vm._e(),(_vm.team)?_c('fw-panel',{staticClass:"my-5",attrs:{"title":"A tua equipa","featured":""}},[_c('div',{staticClass:"flex p-3 w-full gap-6 bg-white rounded-2xl h-16 items-center mt-4 cursor-pointer",on:{"click":function($event){return _vm.goToTeam(_vm.team)}}},[_c('div',{staticClass:"w-24 relative h-16"},[_c('TeamAvatar',{staticClass:"absolute -top-4 left-2",attrs:{"team":_vm.team}})],1),_c('div',{staticClass:"flex-1 flex justify-start"},[_c('div',{staticClass:"font-bold text-center border-2 rounded-full px-4 py-0.5",style:({
                color: _vm.team.color ?? '#777777',
                borderColor: _vm.team.color ?? '#777777',
              })},[(_vm.team.name && _vm.team.name.length > 0)?_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.team.name))]):_c('span',{staticClass:"overflow-ellipsis overflow-hidden whitespace-nowrap"},[_vm._v("Sem nome")])],1)]),_c('div',{staticClass:"flex items-center justify-end gap-2"},[_vm._l((_vm.firstFourPlayers),function(player,p){return _c('div',{key:'player_' + p},[(_vm.users[player])?_c('fw-avatar',{attrs:{"size":"xs","user":_vm.users[player]}}):_vm._e()],1)}),(_vm.playersExcess > 0)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v("+"+_vm._s(_vm.playersExcess))]):_vm._e()],2),(_vm.team.status === 'approved')?_c('div',{staticClass:"text-sm text-primary font-semibold flex gap-1 items-center"},[_vm._v(" Aprovada "),_c('fw-icon-shield-check',{staticClass:"h-10 w-10 text-primary"})],1):(_vm.team.status === 'submitted')?_c('div',{staticClass:"text-sm text-red-600 font-semibold flex gap-1 items-center"},[_vm._v(" Aguarda aprovação "),_c('fw-icon-exclamation-triangle',{staticClass:"h-10 w-10 text-red-500"})],1):(_vm.team.status === 'canceled')?_c('div',{staticClass:"text-sm text-gray-500 font-semibold flex gap-1 items-center"},[_vm._v(" Cancelada ")]):_vm._e(),_c('div',[_c('fw-button',{attrs:{"type":"link","size":"sm"}},[_vm._v(" Ver equipa ")])],1)])]):_vm._e(),(_vm.loadingStages)?_c('LoadingPlaceholder'):[(!_vm.hasOnlyFinalStage && _vm.tournamentStages)?_c('fw-panel',{staticClass:"my-5",attrs:{"title":"Fases","featured":""}},[_c('div',{staticClass:"flex flex-col"},[_c('fw-heading',{staticClass:"mt-5 mb-2",attrs:{"muted":""}},[_vm._v(_vm._s(_vm.stagesList.intragroups[_vm.language]))]),_vm._l((_vm.tournamentStages?.intragroups),function(stage,i){return _c('RecordStage',{key:stage.key,class:{
                'border-b border-gray-100': i < _vm.tournamentStages?.intragroups.length - 1,
              },attrs:{"stage":stage,"team-group":_vm.teamGroups[stage.team_group],"organic-unit":_vm.teamGroups[stage.team_group]
                  ? _vm.organicUnits[_vm.teamGroups[stage.team_group].organic_unit]
                  : _vm.organicUnits[stage.organic_unit]},on:{"open":function($event){return _vm.gotToStage(stage)}}})})],2),_c('div',{staticClass:"flex flex-col"},[_c('fw-heading',{staticClass:"mt-5 mb-2",attrs:{"muted":""}},[_vm._v(_vm._s(_vm.stagesList.intergroups[_vm.language]))]),_vm._l((_vm.tournamentStages?.intergroups),function(stage,i){return _c('RecordStage',{key:stage.key,class:{
                'border-b border-gray-100': i < _vm.tournamentStages?.intergroups.length - 1,
              },attrs:{"stage":stage,"team-group":_vm.teamGroups[stage.team_group],"organic-unit":_vm.teamGroups[stage.team_group]
                  ? _vm.organicUnits[_vm.teamGroups[stage.team_group].organic_unit]
                  : _vm.organicUnits[stage.organic_unit]},on:{"open":function($event){return _vm.gotToStage(stage)}}})})],2),_c('div',{staticClass:"flex flex-col"},[_c('fw-heading',{staticClass:"mt-5 mb-2",attrs:{"muted":""}},[_vm._v(_vm._s(_vm.stagesList.final[_vm.language]))]),_vm._l((_vm.tournamentStages?.final),function(stage,i){return _c('RecordStage',{key:stage.key,class:{
                'border-b border-gray-100': i < _vm.tournamentStages?.final.length - 1,
              },attrs:{"stage":stage,"team-group":_vm.teamGroups[stage.team_group],"organic-unit":_vm.teamGroups[stage.team_group]
                  ? _vm.organicUnits[_vm.teamGroups[stage.team_group].organic_unit]
                  : _vm.organicUnits[stage.organic_unit]},on:{"open":function($event){return _vm.gotToStage(stage)}}})})],2)]):_c('PanelChallongeIframe',{staticClass:"my-5",attrs:{"challonge":_vm.tournament?.stages?.final?.[0]?.challonge,"featured":""}})],_c('PanelTeams',{attrs:{"teams":_vm.teams,"loading":_vm.loading,"is-tournament":true}}),_c('fw-panel-info',{attrs:{"debug":"","label":"Data (raw)"}},[_c('json-viewer',{attrs:{"value":{ league: _vm.league, team: _vm.team, edition: _vm.edition, sport: _vm.sport, tournament: _vm.tournament }}})],1),_c('BannerSportUC')],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }